import { useEffect, useState } from "react";

const PdaButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installSource, setInstallSource] = useState(null);

  const installAppClickHandler = async () => {
    setInstallSource("customInstallationButton");

    if (deferredPrompt) {
      deferredPrompt.prompt();

      try {
        const choiceResult = await deferredPrompt.userChoice;

        if (choiceResult.outcome === "accepted") {
          setDeferredPrompt(null);
        }
      } catch (error) {
        console.error("Error during prompt:", error);
      }
    } else {
      showToast("App is already installed.");
    }
  };

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      const buttonContainer = document.getElementById("installApp");
      buttonContainer.style.display = "block";

      setDeferredPrompt(e);
      setInstallSource("nativeInstallCard");

      e.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setDeferredPrompt(null);
        }
      });
    };

    const appInstalledHandler = () => {
      setDeferredPrompt(null);
      const source = installSource || "browser";
      console.log("App installed from:", source);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    window.addEventListener("appinstalled", appInstalledHandler);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
      window.removeEventListener("appinstalled", appInstalledHandler);
    };
  }, [deferredPrompt, installSource]);

  const showToast = (message) => {
    alert(message);
  };

  return (
    <li className="nav-item" id="installApp" style={{ display: "none" }}>
      <button className="nav-link btn-primary" onClick={installAppClickHandler}>
        <span className="text-light" style={{ cursor: "pointer" }}>
          Install App
        </span>
      </button>
    </li>
  );
};

export default PdaButton;

import { LoadingGif } from "../utils/images.util";

const Loading = () => {
  return (
    <div className="min-vh-100 d-flex flex-column justify-content-center">
      <img
        src={LoadingGif}
        width="60px"
        style={{ margin: "auto" }}
        alt="loader"
      />
    </div>
  );
};

export default Loading;

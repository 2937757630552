import { useState, useEffect } from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

import {
  LogoColored,
  LogoWhite,
  MoonIcon,
  SunIcon,
} from "../utils/images.util";

import useIsDesktop from "../hooks/useIsDesktop";

import { setCurrentUser, setTheme } from "../redux/actions";
import { PowerIcon } from "@heroicons/react/24/outline";
import Confirmation from "./confirmation.component";
import PdaButton from "./pdaButton.component";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const lightTheme = {
    "--dark": "#111827",
    "--dark-rgb": "17, 24, 39",
    "--darker": "#0e131f",
    "--darker-rgb": "14, 19, 31",
    "--light": "#f9fafb",
    "--light-rgb": "249, 250, 251",
    "--light-accent": "#f3f4f6",
    "--light-accent-rgb": "243, 244, 246",
    "--invert-rte-toolbar-color": "invert(0)",
  };

  const darkTheme = {
    "--dark": "#f9fafb",
    "--dark-rgb": "249, 250, 251",
    "--darker": "#f3f4f6",
    "--darker-rgb": "243, 244, 246",
    "--light": "#111827",
    "--light-rgb": "17, 24, 39",
    "--light-accent": "#0e131f",
    "--light-accent-rgb": "14, 19, 31",
    "--invert-rte-toolbar-color": "invert(1)",
  };

  const { categories, colorTheme, currentUser } = useSelector((state) => state);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (localStorage.getItem("color-theme") === "dark") {
      dispatch(setTheme("dark"));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const theme = colorTheme === "light" ? lightTheme : darkTheme;
    Object.keys(theme).forEach((key) => {
      const value = theme[key];
      document.documentElement.style.setProperty(key, value);
    });
    // eslint-disable-next-line
  }, [colorTheme]);

  const toggleTheme = () => {
    const newMode = colorTheme === "light" ? "dark" : "light";
    dispatch(setTheme(newMode));
    localStorage.setItem("color-theme", newMode);
  };

  const handleLogout = () => {
    dispatch(setCurrentUser(undefined));
    localStorage.removeItem("user");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light py-3 px-3 fixed-top">
        <div className="container">
          {isDesktop && (
            <a className="navbar-brand" href="/">
              <img
                src={colorTheme === "light" ? LogoColored : LogoWhite}
                alt="logo"
              />
            </a>
          )}
          {!isDesktop && (
            <div className="hstack ms-auto gap-2">
              <a className="navbar-brand" href="/">
                <img
                  src={colorTheme === "light" ? LogoColored : LogoWhite}
                  alt="logo"
                />
              </a>
              <input
                className="form-control search-bar"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" && navigate(`/search?keyword=${search}`)
                }
              />
              {colorTheme === "light" ? (
                <button
                  className="theme-btn"
                  onClick={toggleTheme}
                  style={{ backgroundColor: "transparent" }}
                >
                  <MoonIcon />
                </button>
              ) : (
                <button className="theme-btn" onClick={toggleTheme}>
                  <SunIcon />
                </button>
              )}
              <button
                className="theme-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <Bars3Icon />
              </button>
            </div>
          )}
          <div className="collapse navbar-collapse" id="navbar">
            {isDesktop && (
              <div className="input-group me-auto search-input-group">
                <input
                  type="text"
                  className="form-control search-bar"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-icon"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && navigate(`/search?keyword=${search}`)
                  }
                />
                <button
                  onClick={() => navigate(`/search?keyword=${search}`)}
                  className="input-group-text"
                  id="search-icon"
                >
                  <MagnifyingGlassCircleIcon />
                </button>
              </div>
            )}
            <ul className="navbar-nav ms-auto gap-3">
              <li className="nav-item">
                <a className="nav-link active" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  style={{ fontWeight: 400 }}
                  type="button"
                  id="categoriesDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Categories
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="categoriesDropdown"
                >
                  {categories.map((c) => (
                    <li key={c._id}>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate(`/search?category=${c.name}`)}
                      >
                        {c.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/discover">
                  Discover
                </Link>
              </li>
              {!isDesktop && currentUser && (
                <li className="nav-item">
                  <button className="nav-link" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              )}
              {isDesktop && (
                <>
                  <div className="vr vr-1" />
                  {colorTheme === "light" ? (
                    <li className="nav-item" onClick={toggleTheme}>
                      <MoonIcon />
                    </li>
                  ) : (
                    <li className="nav-item" onClick={toggleTheme}>
                      <SunIcon />
                    </li>
                  )}
                  {currentUser && (
                    <li
                      className="nav-item outline"
                      data-bs-toggle="modal"
                      data-bs-target="#confirm-logout"
                    >
                      <PowerIcon />
                    </li>
                  )}
                </>
              )}
              <PdaButton />
            </ul>
          </div>
        </div>
      </nav>
      <Confirmation
        id="confirm-logout"
        content={`Are you sure you want to logout?`}
        handleConfirm={() => handleLogout()}
      />
    </>
  );
};

export default Navbar;

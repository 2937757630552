import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { loginUser } from "../services/user.service";
import { setCurrentUser } from "../redux/actions";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (email !== "" && password !== "") {
      loginUser({ email: email, password: password })
        .then((res) => {
          if (res.data.accessToken) {
            toast("Welcome, " + res.data.name);
            localStorage.setItem("user", JSON.stringify(res.data));
            dispatch(setCurrentUser(res.data));
            navigate("/dashboard");
          }
        })
        .catch(() => toast("Authentication failed, try again."));
    } else {
      toast("All fields are required");
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center">
      <div className="glass-wrapper">
        <div className="circle-1"></div>
        <div className="circle-2"></div>
      </div>
      <div className="container">
        <div className="login-form">
          <h5>Admin Login</h5>
          <div className="form-group">
            <label htmlFor="loginEmail">Email address</label>
            <input
              type="email"
              className="form-control shadow-none"
              id="loginEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleLogin()}
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="loginPassword">Password</label>
            <input
              type="password"
              className="form-control shadow-none"
              id="loginPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleLogin()}
              placeholder="Password"
            />
          </div>
          <button
            type="button"
            className="btn btn-primary shadow-none"
            onClick={handleLogin}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;

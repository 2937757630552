import http from "../common/http-common";
import authHeader from "./auth.service";

export const createArticle = async (data) => {
  return http.post("/article/create", data, { headers: authHeader() });
};

export const getSearchedArticles = async (params) => {
  return http.get(`/article/search`, { params });
};

export const getArticles = async (params) => {
  return http.get(`/article/get`, { params });
};

export const getLatestArticles = async () => {
  return http.get("/article/latest");
};

export const getTrendingArticles = async () => {
  return http.get("/article/trending");
};

export const getAuthorPicks = async () => {
  return http.get("/article/author-picks");
};

export const getArticle = async (title) => {
  return http.get(`/article/get/${title}`);
};

export const getTags = async () => {
  return http.get("/article/tags");
};

export const updateAuthorPick = async (userId, articleId) => {
  return http.put(
    `/article/author-pick/${userId}`,
    { article_id: articleId },
    {
      headers: authHeader(),
    }
  );
};

export const beginArticleUpdate = async (id) => {
  return http.put(
    `/article/being-updated/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const cancelArticleUpdate = async (id) => {
  return http.put(
    `/article/cancel-update/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const updateArticle = async (article) => {
  return http.put(`/article/update/${article._id}`, article, {
    headers: authHeader(),
  });
};

export const updateArticleLikes = async (id, likes) => {
  return http.put(`/article/likes/${id}`, { likes: likes });
};

export const deleteArticle = async (id) => {
  return http.delete(`/article/delete/${id}`, { headers: authHeader() });
};

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./styles/styles.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-toastify/dist/ReactToastify.css";

import Routing from "./router/router";
import store from "./redux/store";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Provider store={store}>
      <Routing />
    </Provider>
  </Router>
);

reportWebVitals();

import http from "../common/http-common";
import authHeader from "./auth.service";

export const createCategory = async (data) => {
  return http.post("/category/create", data, {
    headers: authHeader(),
  });
};

export const getCategories = async () => {
  return http.get("/category/get");
};

export const updateCategory = async (id, data) => {
  return http.put(`/category/update/${id}`, data, {
    headers: authHeader(),
  });
};

export const deleteCategory = async (id) => {
  return http.delete(`/category/delete/${id}`, {
    headers: authHeader(),
  });
};
